import * as React from "react";
import "../../styles.css";

export default function StandingExpanded({ standing }) {
  return (
    <div className="fCSC ">
      {standing.expHeading && (
        <div className="cardTextExpanded headingBig">
          {standing.expHeading}
          <p></p>
        </div>
      )}
      {standing.explaination && (
        <div className="cardTextExpanded">
          "{standing.explaination}"<p></p>
        </div>
      )}
      {standing.goalHeading && <div className="cardTextExpanded headingMedium">{standing.goalHeading}</div>}
      {standing.goals &&
        standing.heading !== "Krankenhäuser" &&
        standing.goals.intros.map((intro, index) => (
          <>
            <div className="cardTextExpanded headingSmall">{intro}</div>
            {standing.goals.statements[index] && <div className="cardTextExpanded">{standing.goals.statements[index]}</div>}
            <p />
          </>
        ))}
      {standing.heading === "Krankenhäuser" && (
        <div className="cardTextExpanded">
          <ul>
            {standing.goals &&
              standing.goals.intros.map((intro, index) => (
                <li>
                  {standing.goals.statements[index] && <div className="cardTextExpanded noPadding">{standing.goals.statements[index]}</div>}
                  <p />
                </li>
              ))}
          </ul>
        </div>
      )}
      {standing.summaryHeading && <div className="cardTextExpanded headingMedium">{standing.summaryHeading}</div>}
      {standing.summary && (
        <div className="cardTextExpanded">
          {standing.summary}
          <p></p>
        </div>
      )}
    </div>
  );
}
