import "./aboutMe.css";
import rene from "../../content/rene_berufung.jpeg";
import { useMediaQuery } from "@mui/material";

export default function ProfileCard() {
  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  return (
    <>
      {!isSmallScreen && (
        <div className="fCSC profileCardContainer">
          <h1 className="profileContentHeader defaultText black">Profil des Kandidaten</h1>
          <div className="fSSR profileContentContainer">
            <div className="fSSC profileLeftContentContainer">
              <div className="fSSC defaultText profile text black">
                <text className="defaultText profile head black bold">Familienstand</text>
                <ul>
                  <li>Verheiratet seit 15 Jahren</li>
                  <li>2 Söhne, 1 Stieftochter</li>
                </ul>
              </div>
              <div className="fCCC profileSeperatorHori"></div>
              <div className="fSSC defaultText profile text black">
                <text className="defaultText profile head black bold">Ausbildungen</text>
                <ul>
                  <li>Energieelektroniker</li>
                  <li>Rettungssanitäter</li>
                  <li>Verwaltungsfachangestellter</li>
                </ul>
              </div>
              <div className="fCCC profileSeperatorHori"></div>
              <div className="fSSC defaultText profile text black">
                <text className="defaultText profile head black bold">Berufliche Tätigkeiten</text>
                <ul>
                  <li>Fachadministrator im Landratsamt Nordsachsen</li>
                  <li>Personalrat im Landratsamt Nordsachsen</li>
                </ul>
              </div>
            </div>
            <div className="fSSR profileSeperatorVert"></div>
            <div className="fSSC profileRightContentContainer">
              <div className="fSSC defaultText profile text black">
                <text className="defaultText profile head black bold">Ehrenämter</text>
                <ul>
                  <li>Zugführer in der Freiwilligen Feuerwehr Beilrode</li>
                  <li>Kreisausbilder der Feuerwehren Nordsachsen</li>
                  <li>Mitglied im Katastrophenschutz Nordsachsen</li>
                  <li>Mitglied der Technischen Einsatzleitung Nordsachsen</li>
                  <li>Mitglied im Kreisvorstand der SPD Nordsachsen</li>
                </ul>
              </div>
              <img src={rene} className="profileImg" alt="Rene" />
            </div>
          </div>
        </div>
      )}
      {isSmallScreen && (
        <div className="fCSC profileCardContainer">
          <div className="fSSC profileContentContainer">
            <div className="fSSC profileContentContainer">
              <div className="fSSC defaultText profile text black">
                <text className="defaultText profile head black bold">Familienstand</text>
                <ul>
                  <li>Verheiratet seit 15 Jahren</li>
                  <li>2 Söhne, 1 Stieftochter</li>
                </ul>
              </div>
              <div className="fCCC profileSeperatorHori"></div>
              <div className="fSSC defaultText profile text black">
                <text className="defaultText profile head black bold">Ausbildungen</text>
                <ul>
                  <li>Energieelektroniker</li>
                  <li>Rettungssanitäter</li>
                  <li>Verwaltungsfachangestellter</li>
                </ul>
              </div>
              <div className="fCCC profileSeperatorHori"></div>
              <div className="fSSC defaultText profile text black">
                <text className="defaultText profile head black bold">Berufliche Tätigkeiten</text>
                <ul>
                  <li>Fachadministrator im Landratsamt Nordsachsen</li>
                  <li>Personalrat im Landratsamt Nordsachsen</li>
                </ul>
              </div>
              <div className="fCCC profileSeperatorHori"></div>
              <img src={rene} className="profileImg" alt="Rene" />
              <div className="fSSC defaultText profile text black">
                <text className="defaultText profile head black bold">Ehrenämter</text>
                <ul>
                  <li>Zugführer in der Freiwilligen Feuerwehr Beilrode</li>
                  <li>Mitglied im Katastrophenschutz Nordsachsen</li>
                  <li>Kreisausbilder der Feuerwehren Nordsachsen</li>
                  <li>Mitglied der Technischen Einsatzleitung Nordsachsen</li>
                  <li>Mitglied im Kreisvorstand der SPD Nordsachsen</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
