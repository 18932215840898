import { useEffect, useState } from "react";
import "../styles.css";

export default function Impressum() {
  const [isEntered, setEntered] = useState(false);

  useEffect(() => {
    if (!isEntered) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setEntered(false);
  }, []);

  return (
    <div className="fCCC">
      <div className="fCCC legalContainer">
        <div className="fCCC legalText">
          <h1 id="impressum">Impressum</h1>
          <p>
            <strong>Verantwortlich: </strong>
            SPD Kreisverband Nordsachsen vertreten durch Stefan Lange
          </p>
          <p>
            <strong>Kontakt: </strong>
            c/o Rosa-Luxemburg-Str. 19/21, 04103 Leipzig, Deutschland
          </p>
          <p>
            Tel: +49 175 7415855, Mail: <a href="mailto:info@spd-nordsachsen.de">info@spd-nordsachsen.de</a>, Web:
            <a href="https://spd-nordsachsen.de">https://spd-nordsachsen.de</a>
          </p>
          <p>
            Verantwortlich für den Inhalt (gem. § 55 Abs. 2 RStV): SPD Kreisverband Nordsachsen vertreten durch Stefan Lange, Rosa-Luxemburg-Str.
            19/21 in 04103 Leipzig, Deutschland. Mail: info@spd-nordsachsen.de, Tel.: 0175/7415855
          </p>
          <h2 id="haftungsausschluss">Haftungsausschluss</h2>
          <h3 id="haftungFuerInhalte">Haftung für Inhalte</h3>
          <p>
            Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
            jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
            Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte
            fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
            Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
            Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
          <h3 id="haftung-f-r-links">Haftung für Links</h3>
          <p>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
            Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
            Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
            konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
            entfernen.
          </p>
          <h3 id="urheberrecht">Urheberrecht</h3>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
            Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
            jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
            Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
          <h3 id="datenschutz">Datenschutz</h3>
          <p>
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene
            Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis.
            Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im
            Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
            Dritte ist nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von
            nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten
            behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
          </p>
          <h3 id="google-analytics">Google Analytics</h3>
          <p>
            Diese Website benutzt ggf. Google Analytics, einen Webanalysedienst der Google Inc. (&#39;&#39;Google&#39;&#39;). Google Analytics
            verwendet sog. &#39;&#39;Cookies&#39;&#39;, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der
            Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website (einschließlich Ihrer
            IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre
            Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an
            Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in
            keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie können die Installation der Cookies durch eine
            entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
            nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der
            Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck
            einverstanden.
          </p>
          <h3 id="google-adsense">Google AdSense</h3>
          <p>
            Diese Website benutzt ggf. Google Adsense, einen Webanzeigendienst der Google Inc., USA (&#39;&#39;Google&#39;&#39;). Google Adsense
            verwendet sog. &#39;&#39;Cookies&#39;&#39; (Textdateien), die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der
            Website durch Sie ermöglicht. Google Adsense verwendet auch sog. &#39;&#39;Web Beacons&#39;&#39; (kleine unsichtbare Grafiken) zur
            Sammlung von Informationen. Durch die Verwendung des Web Beacons können einfache Aktionen wie der Besucherverkehr auf der Webseite
            aufgezeichnet und gesammelt werden. Die durch den Cookie und/oder Web Beacon erzeugten Informationen über Ihre Benutzung dieser Website
            (einschließlich Ihrer IP-Adresse) werden an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese
            Informationen benutzen, um Ihre Nutzung der Website im Hinblick auf die Anzeigen auszuwerten, um Reports über die Websiteaktivitäten und
            Anzeigen für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
            Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
            vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen
            Daten der Google in Verbindung bringen. Das Speichern von Cookies auf Ihrer Festplatte und die Anzeige von Web Beacons können Sie
            verhindern, indem Sie in Ihren Browser-Einstellungen &#39;&#39;keine Cookies akzeptieren&#39;&#39; wählen (Im MS Internet-Explorer unter
            &#39;&#39;Extras &gt; Internetoptionen &gt; Datenschutz &gt; Einstellung&#39;&#39;; im Firefox unter &#39;&#39;Extras &gt; Einstellungen
            &gt; Datenschutz &gt; Cookies&#39;&#39;); wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über
            Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
          </p>
        </div>
      </div>
    </div>
  );
}
