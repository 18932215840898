import "../../styles.css";

export default function Vocation({ index, heading, text, isSmallScreen }) {
  return (
    <>
      {!isSmallScreen && (
        <div className="fSSR vocationContainer">
          <div id={"vocationHead" + index} className="fESR vocationHead">
            <>
              <strong>{heading}:</strong>
            </>
          </div>
          <div id={"vocationText" + index} className="fSCR vocationText">
            <>{text}</>
          </div>
        </div>
      )}
      {isSmallScreen && (
        <div className="fCSR vocationContainer">
          <div id={"vocationHead" + index} className="fSSR vocationHead">
            <>
              <strong>{heading}:</strong>
            </>
          </div>
          <div id={"vocationText" + index} className="fSSR vocationText">
            <>{text}</>
          </div>
        </div>
      )}
    </>
  );
}
