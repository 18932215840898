import React, { useEffect, useState } from "react";
import "./aboutMe.css";
import PersonalCard from "./PersonalCard";
import ProfileCard from "./ProfileCard";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function AboutMe() {
  const [isEntered, setEntered] = useState(false);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [topBarHeight, setTopBarHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEntered) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setEntered(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    setScrollbarWidth(getScrollbarWidth());
    getTopBarHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToHeading = (id) => {
    navigate("/");
    setTimeout(() => {
      const heading = document.getElementById(id);
      const topbarHeight = document.querySelector(".topbar").offsetHeight;
      if (id === "start") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        if (heading) {
          const headingOffset = heading.getBoundingClientRect().top + window.scrollY + 3;
          window.scrollTo({
            top: headingOffset - topbarHeight,
            behavior: "smooth",
          });
        }
      }
    }, 100);
  };

  function getScrollbarWidth() {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll";
    outer.style.msOverflowStyle = "scrollbar";
    document.body.appendChild(outer);
    const inner = document.createElement("div");
    outer.appendChild(inner);
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode.removeChild(outer);
    return scrollbarWidth;
  }

  function getTopBarHeight() {
    setTopBarHeight(document.querySelector(".topbar").offsetHeight);
  }

  return (
    <>
      {!isSmallScreen && (
        <div className="fCCR contentFrameAboutMe" style={{ width: screenWidth - scrollbarWidth }}>
          <PersonalCard></PersonalCard>
          <ProfileCard></ProfileCard>
        </div>
      )}
      {isSmallScreen && (
        <div className="fCCC contentFrameAboutMe" style={{ width: screenWidth - scrollbarWidth, paddingTop: topBarHeight }}>
          <PersonalCard></PersonalCard>
          <ProfileCard></ProfileCard>
        </div>
      )}
    </>
  );
}
