import "./aboutMe.css";
import rene from "../../content/fischer_rene_start.png";
import spd_rot from "../../content/SPD_Logo_Rot.svg";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function PersonalCard() {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  return (
    <>
      {!isSmallScreen && (
        <div className="fCCC personalCardContainer">
          <img src={rene} className="personalImage" alt="RENE" />

          <div className="fCCC personalSeperator"></div>
          <div className="fCCR personalContentContainer">
            <div className="fSSC personalHeader defaultText black bold">
              <text>Name</text>
              <text>Alter</text>
              <text>Wohnort</text>
              <text>Telefon</text>
            </div>
            <div className="fSSC personalText defaultText black">
              <text>René Fischer</text>
              <text>51 Jahre</text>
              <text>04886 Beilrode</text>
              <text>
                <a href="tel:+491757415855">+49 175 7415855</a>
              </text>
            </div>
          </div>
          <div className="fCCC personalSeperator"></div>
          <div className="fCCC personalText full defaultText black bold">
            <text>Kandidat für die SPD Nordsachsen</text>
          </div>
          <img src={spd_rot} className="personalImage spd" alt="not available"></img>
        </div>
      )}
      {isSmallScreen && (
        <div className="fCCC personalCardContainer">
          <h1 className="fCSC personalText full defaultText black bold big">Profil des Kandidaten</h1>
          <img src={rene} className="personalImage" alt="RENE" />

          <div className="fCCC personalSeperator"></div>
          <div className="fCCR personalContentContainer">
            <div className="fSSC personalHeader defaultText black bold">
              <text>Name</text>
              <text>Alter</text>
              <text>Wohnort</text>
              <text>Telefon</text>
            </div>
            <div className="fSSC personalText defaultText black">
              <text>René Fischer</text>
              <text>51 Jahre</text>
              <text>04886 Beilrode</text>
              <text>
                <a href="tel:+491757415855">+49 175 7415855</a>
              </text>
            </div>
          </div>

          <div className="fCCC personalSeperator"></div>
          <div className="fCSC personalText full defaultText black bold">
            <text>Kandidat für die SPD Nordsachsen</text>
          </div>
          <img src={spd_rot} className="" alt="not available"></img>
        </div>
      )}
    </>
  );
}
