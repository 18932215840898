import "../../styles.css";
import reneFischer from "../../content/fischer_rene_start.png";
import { useEffect, useRef } from "react";
import Wahlspruch from "./sprueche/Wahlspruch";
import ForestIcon from "@mui/icons-material/Forest";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import statements from "../../content/texts/statements.json";

export default function Start() {
  const imgRef = useRef(null);
  const imgRefBack = useRef(null);

  useEffect(() => {
    imgRef.current.style.animation = "fadeIn 1s ease-in-out";
    imgRef.current.style.opacity = 1;
    imgRefBack.current.style.animation = "moveIn 1s ease-in-out forwards";
  }, []);

  const icons = [
    <ForestIcon style={{ width: 35, height: 35 }} sx={{ color: "white" }} />,
    <VolunteerActivismIcon style={{ width: 35, height: 35 }} sx={{ color: "white" }} />,
    <DoneAllIcon style={{ width: 35, height: 35 }} sx={{ color: "white" }} />,
  ];

  return (
    <div className="fCCR startContainer">
      <div className="fillerContainer">
        <div ref={imgRefBack} className="startBackgroundName">
          <text>René</text>
        </div>
      </div>
      <div className="fCER startImageContainer">
        <img ref={imgRef} src={reneFischer} className="startImage" alt="not available"></img>
      </div>
      <div className="fCSC spruecheContainer">
        {statements.map((statement, index) => (
          <Wahlspruch key={index} icon={icons[index]} statement={statement} delay={index * 0.2} />
        ))}
      </div>
    </div>
  );
}
