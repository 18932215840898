import "../styles.css";
import { useEffect, useState } from "react";

export default function PrivacyPolicy() {
  const [isEntered, setEntered] = useState(false);

  useEffect(() => {
    if (!isEntered) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setEntered(false);
  }, []);

  return (
    <div className="fCCC">
      <div className="fCCC legalContainer">
        <div className="fCCC legalText">
          <h1 id="verantwortlich-im-sinne-des-datenschutzgesetzes-">Datenschutzerklärung</h1>
          <p>
            <strong>Verantwortlich im Sinne des Datenschutzgesetzes: </strong>SPD Kreisverband Nordsachsen
          </p>
          <p>
            <strong>Kontakt: </strong>c/o Rosa-Luxemburg-Str. 19/21, 04103 Leipzig, Deutschland, <text>Tel.: 0175/7415855</text>
          </p>
          <h2 id="datenschutz">Datenschutz</h2>
          <p>
            Als Webseitenbetreiber nehmen wir den Schutz aller persönlichen Daten sehr ernst. Alle personenbezogenen Informationen werden vertraulich
            und gemäß den gesetzlichen Vorschriften behandelt, wie in dieser Datenschutzerklärung erläutert.
          </p>
          <p>
            Unsere Webseite kann selbstverständlich genutzt werden, ohne dass Sie persönliche Daten angeben. Wenn jedoch zu irgendeinem Zeitpunkt
            persönliche Daten wie z.B. Name, Adresse oder E-Mail abgefragt werden, wird dies auf freiwilliger Basis geschehen. Niemals werden von uns
            erhobene Daten ohne Ihre spezielle Genehmigung an Dritte weitergegeben.
          </p>
          <p>
            Datenübertragung im Internet, wie zum Beispiel über E-Mail, kann immer Sicherheitslücken aufweisen. Der komplette Schutz der Daten ist im
            Internet nicht möglich.
          </p>
          <h2 id="auskunft-l-schung-sperrung">Auskunft, Löschung, Sperrung</h2>
          <p>
            Zu jedem Zeitpunkt können Sie sich über die personenbezogenen Daten, deren Herkunft und Empfänger und den Nutzen der Datenverarbeitung
            informieren und unentgeltlich eine Korrektur, Sperrung oder Löschung dieser Daten verlangen. Bitte nutzen Sie dafür die im Impressum
            angegebenen Kontaktwege. Erhobene Daten über das Kontaktformular (E-Mails) werden spätestens 14 Tage nach Durchführung der Landtagswahl in
            Sachsen am 01. September 2024 gelöscht. Für weitere Fragen zum Thema stehen wir Ihnen ebenfalls jederzeit zur Verfügung.
          </p>
          <h2 id="server-log-files">Server-Log-Files</h2>
          <p>
            Der Seiten-Provider erhebt und speichert automatisch Daten in Server-Log Files, die von Ihrem Browser an uns übermittelt werden. Diese
            Daten enthalten:
          </p>
          <ul>
            <li>Browsertyp/ Browserversion</li>
            <li>Betriebssystem des Rechners</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
          </ul>
          <p>
            Diese Daten sind nicht personenbezogen. Es erfolgt keine Zusammenführung dieser Daten mit anderen Datenquellen. Wenn uns konkrete
            Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden behalten wir uns das Recht vor, diese Daten nachträglich zu überprüfen.
          </p>
          <h2 id="cookies">Cookies</h2>
          <p>
            Viele Internetseiten verwenden Cookies. Cookies sind unschädlich für Ihren Rechner und virenfrei. Sie dienen dazu, Internet-Angebote für
            die Besucher einer freundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Computer abgelegt
            werden und die Ihr Browser verwendet.
          </p>
          <p>
            Wir verwenden in der Regel so genannte „Session-Cookies“. Diese werden nach Verlassen unserer Seite automatisch gelöscht. Andere Cookies
            bleiben auf Ihrem Computer gespeichert, bis Sie diese löschen. Diese Cookies helfen dabei, Ihren Rechner beim nächsten Besuch
            wiederzuerkennen.
          </p>
          <p>
            Über die Browsereinstellungen können sie festlegen, dass Sie über neue Cookies informiert werden und Cookies jeweils annehmen müssen.
            Ebenso können Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschließen oder das automatische Löschen der Cookies beim
            Schließen des Browser aktivieren. Werden Cookies desaktiviert, kann die Funktionalität unserer Website eingeschränkt sein.
          </p>
          <h2 id="kontaktformular">Kontaktformular</h2>
          <p>
            Kontaktdaten, die uns über unser Kontaktformular erreichen, werden inklusive des Inhalts der Anfrage für Bearbeitungszwecke und für
            mögliche Anschlussfragen gespeichert. Diese Daten werden ohne Ihre spezifische Zustimmung nicht weitergegeben. (Außerdem siehe Löschfrist
            unter "Auskunft, Löschung, Sperrung")
          </p>
          <h2 id="änderung-der-datenschutzbestimmungen">Änderung der Datenschutzbestimmungen</h2>
          <p>
            Unsere Datenschutzerklärung können in unregelmäßigen Abständen angepasst werden, damit sie den aktuellen rechtlichen Anforderungen
            entsprechen oder um Änderungen unserer Dienstleistungen umzusetzen, z. B. bei der Einfügung neuer Angebote. Für Ihren nächsten Besuch gilt
            dann automatisch die neue Datenschutzerklärung.
          </p>
          <h2 id="kontakt-zum-datenschutzmitarbeiter">Kontakt zum Datenschutzmitarbeiter</h2>
          <p>
            Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an{" "}
            <a href="mailto:datenschutz@spd-nordsachsen.de">datenschutz@spd-nordsachsen.de</a> mit dem Betreff „<strong>Datenschutz</strong>“.
          </p>
        </div>
      </div>
    </div>
  );
}
