/* eslint-disable react-hooks/exhaustive-deps */
import StandingCard from "./StandingCard";
import standings from "../../content/texts/standings.json";
import "../../styles.css";

import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import FireTruckIcon from "@mui/icons-material/FireTruck";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import SecurityIcon from "@mui/icons-material/Security";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import HealingIcon from "@mui/icons-material/Healing";
import PublicIcon from "@mui/icons-material/Public";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import React, { useEffect, useState } from "react";

export default function Standings() {
  const icons = [
    <VolunteerActivismIcon class="cardIcon" />,
    <FireTruckIcon class="cardIcon" />,
    <WarningAmberIcon class="cardIcon" />,
    <HealingIcon class="cardIcon" />,
    <SecurityIcon class="cardIcon" />,
    <MinorCrashIcon class="cardIcon" />,
    <LocalPoliceIcon class="cardIcon" />,
    <LocalHospitalIcon class="cardIcon" />,
    <MenuBookIcon class="cardIcon" />,
    <PublicIcon class="cardIcon" />,
  ];
  const [cards, setCards] = useState([]);
  const [backCards, setBackCards] = useState([]);
  const [index, setIndex] = useState(undefined);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = (index) => {
    setIndex(index);
    setIsExpanded(true);
    scrollToCard("standings");
  };

  const handleDownsize = (index) => {
    setIndex(index);
    setIsExpanded(false);
    scrollToCard("standings");
  };

  useEffect(() => {
    const newCards = [];
    standings.map((standing, index) => {
      newCards.push(
        <StandingCard key={index} icon={icons[index]} standing={standing} index={index} handleExpand={handleExpand} handleDownsize={handleDownsize} />
      );
    });
    setCards(newCards);
  }, []);

  useEffect(() => {
    if ((index === 0) | index && isExpanded) {
      setBackCards(cards);
      setCards(cards[index]);
    }
    if ((index === 0) | index && !isExpanded) {
      setCards(backCards);
    }
  }, [isExpanded]);

  const scrollToCard = (id) => {
    setTimeout(() => {
      const heading = document.getElementById(id);
      const topbarHeight = document.querySelector(".topbar").offsetHeight;
      const headingOffset = heading.getBoundingClientRect().top + window.scrollY + 3;
      window.scrollTo({
        top: headingOffset - topbarHeight - (id !== "standings" ? 10 : 0),
        behavior: "smooth",
      });
    }, 100);
  };

  return <>{cards}</>;
}
