import "../styles.css";
import Start from "./start/Start";
import Standings from "./standings/Standings";
import Vocations from "./vocations/Vocations";
import Contact from "./contact/Contact";
import { useEffect, useState } from "react";
import Voting from "./voting/Voting";

export default function ContentPage() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    setScrollbarWidth(getScrollbarWidth());

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getScrollbarWidth() {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll";
    outer.style.msOverflowStyle = "scrollbar";
    document.body.appendChild(outer);
    const inner = document.createElement("div");
    outer.appendChild(inner);
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode.removeChild(outer);
    return scrollbarWidth;
  }

  return (
    <div>
      <div className="fCCC contentframeStart" style={{ width: screenWidth - scrollbarWidth }}>
        <Start></Start>
      </div>
      <div id="standings" className="fCCC seperator" style={{ width: screenWidth - scrollbarWidth }}></div>
      <div className="fCCR contentframeStandings" style={{ width: screenWidth - scrollbarWidth }}>
        <Standings></Standings>
      </div>
      <div id="voting" className="fCCC seperator" style={{ width: screenWidth - scrollbarWidth }}></div>
      <div className="fCCR contentframeVoting" style={{ width: screenWidth - scrollbarWidth }}>
        <Voting></Voting>
      </div>
      <div id="vocation" className="fCCC seperator twice" style={{ width: screenWidth - scrollbarWidth }}></div>
      <div className="fCCR contentframeVocations" style={{ width: screenWidth - scrollbarWidth }}>
        <Vocations></Vocations>
      </div>
      <div id="contact" className="fCCC seperator twice" style={{ width: screenWidth - scrollbarWidth }}></div>
      <div className="fCCR contentframeContact" style={{ width: screenWidth - scrollbarWidth }}>
        <Contact></Contact>
      </div>
      <div id="standings" className="fCCC seperator" style={{ width: screenWidth - scrollbarWidth }}></div>
    </div>
  );
}
