import React, { useState, useEffect } from "react";
import "../../styles.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function BottomBar() {
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ screenWidth }} className="fCCR bottombar">
      <Button onClick={() => navigate("impressum")}>
        <div className="menuItem small">Impressum</div>
      </Button>
      <div className="menuItem"> | </div>
      <Button onClick={() => navigate("privacy-policy")}>
        <div className="menuItem small">Datenschutzerklärung</div>
      </Button>
    </div>
  );
}

export default BottomBar;
