/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import "../../styles.css";
import StandingExpanded from "./StandingExpanded";
import heimatCard from "../../content/heimatCard.png";
import { useMediaQuery } from "@mui/material";

const StandingCard = ({ icon, standing, index, handleExpand, handleDownsize }) => {
  const [isSmall, setSmall] = useState(true);
  const [isScrollable, setScrollable] = useState(false);
  const cardRef = useRef(null);
  const isSmallScreen = useMediaQuery("(max-width:1200px)");

  useEffect(() => {
    if (isScrollable) {
      if (isSmall) {
        handleDownsize(index);
      }
      if (!isSmall) {
        handleExpand(index);
      }
    }
    setScrollable(true);
  }, [isSmall]);

  return (
    <>
      {standing.heading !== "image" && (
        <Card ref={cardRef} id={"card" + index} className={`fSCC standingCard ${isSmall ? "small" : "expanded"}`} sx={{ borderRadius: 0 }}>
          <div className="fCCC cardIconContainer">{icon ? icon : <VolunteerActivismIcon class="cardIcon" />}</div>
          <CardContent className="fSCC standingCardContent">
            <div className="cardHeader">{standing ? standing.heading : ""}</div>
          </CardContent>
          <CardContent className="fCCC standingCardContent small">
            {isSmall ? <div className="cardText">{standing ? `"${standing.statement}"` : ""}</div> : <StandingExpanded standing={standing} />}
          </CardContent>
          <CardActions className="fCCC standingCardContent">
            <Button
              size="small"
              style={{ color: "#E3000F" }}
              onClick={() => {
                setSmall(!isSmall);
              }}
            >
              {isSmall ? "Mehr lesen" : "Zurück"}
            </Button>
          </CardActions>
        </Card>
      )}
      {standing.heading === "image" && !isSmallScreen && (
        <div ref={cardRef} id={"card" + index} className={`fSCC standingCard image`} sx={{ borderRadius: 0, backgroundColor: "transparent" }}>
          <div className="fCCC cardIconContainer solid">
            <img src={heimatCard} className="spdImage" alt="not available"></img>
          </div>
        </div>
      )}
    </>
  );
};

export default StandingCard;
