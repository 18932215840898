import { useEffect, useRef, useState } from "react";
import vocationsJson from "../../content/texts/vocations.json";
import vocationsHead from "../../content/texts/vocationsHead.json";
import reneBerufung from "../../content/rene_berufung.jpeg";
import reneBerufungBack from "../../content/rene_berufung_back.jpeg";
import "../../styles.css";
import Vocation from "./Vocation";
import { Button, useMediaQuery } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

export default function Vocations() {
  const [vocations, setVocations] = useState([]);
  const imgRefBack = useRef(null);
  const imgRef = useRef(null);
  const lastScrollY = useRef(0);
  const [scrollDirection, setScrollDirection] = useState("down");
  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const navigate = useNavigate();

  useEffect(() => {
    imgRefBack.current.style.animation = "moveOutToRight 1s ease-in-out forwards";
    imgRefBack.current.style.opacity = 0;
    imgRef.current.style.animation = "moveOutToRight 1s ease-in-out forwards";
    imgRef.current.style.opacity = 0;
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY.current) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (scrollDirection === "down") {
              imgRef.current.style.animation = "moveInFromRight 1.1s ease-in-out forwards";
              imgRef.current.style.opacity = 1;
              imgRefBack.current.style.animation = "moveInFromRight 1s ease-in-out forwards";
              imgRefBack.current.style.opacity = 1;
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [scrollDirection]);

  useEffect(() => {
    const newVocations = [];
    vocationsJson.map((vocation, index) => {
      newVocations.push(
        <Vocation key={index} index={index} heading={vocation.heading} text={vocation.text} isSmallScreen={isSmallScreen}></Vocation>
      );
    });
    setVocations(newVocations);
  }, []);

  return (
    <>
      {!isSmallScreen && (
        <>
          <h1 className="defaultText"> {vocationsHead.text1} </h1>
          <div className="fCCR vocationsContent">
            <div className="vocationsContainer"> {vocations} </div>
            <div className="fSCR vocationImageContainer">
              <img ref={imgRefBack} src={reneBerufungBack} className="vocationImage" alt="not available"></img>
              <img ref={imgRef} src={reneBerufung} className="vocationImage img" alt="not available"></img>
            </div>
          </div>
          <div
            className="defaultText vocationButton"
            onClick={() => {
              navigate("/about-me");
            }}
          >
            Mehr über mich...
          </div>
        </>
      )}
      {isSmallScreen && (
        <>
          <h1 className="defaultText">
            <u>{vocationsHead.text2_1}</u>
            <br></br> {vocationsHead.text2_2}
          </h1>
          <div className="fSCC vocationsContent">
            <div className="fCSR vocationImageContainer">
              <img ref={imgRefBack} src={reneBerufungBack} className="vocationImage" alt="not available"></img>
              <img ref={imgRef} src={reneBerufung} className="vocationImage img" alt="not available"></img>
            </div>
            <div className="vocationsContainer"> {vocations} </div>
          </div>
          <div
            className="defaultText vocationButton"
            onClick={() => {
              navigate("/about-me");
            }}
          >
            Mehr über mich...
          </div>
        </>
      )}
    </>
  );
}
