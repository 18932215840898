import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import "../../styles.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { green } from "@mui/material/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

export default function Contact() {
  const [formData, setFormData] = useState({ name: "", email: "", subject: "", message: "" });
  const [responseMessage, setResponseMessage] = useState("");
  const [sent, setSent] = useState(false);
  const [sQuest, setSQuest] = useState("");
  const [sAnsw, setSAnsw] = useState("");
  const [result, setResult] = useState(0);
  const [isSQuestPassed, setIsSQuestPassed] = useState(false);
  const [leftTries, setLeftTries] = useState(3);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);

  const generateRandomNumber = () => {
    const number1 = Math.floor(Math.random() * 10) + 1;
    const number2 = Math.floor(Math.random() * 10) + 1;
    const numbers = [number1, number2];
    setSQuest(numbers[0] + " + " + numbers[1]);
    setResult(numbers[0] + numbers[1]);
  };
  const maxLength = 2500;

  const handleChangeArea = (event) => {
    handleChange(event);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSecurity = (e) => {
    const value = e.target.value;
    if (value !== "") {
      setSAnsw(parseInt(value, 10));
    } else {
      setSAnsw(value);
    }
  };

  const handlePrivacyCheck = (e) => {
    setIsPrivacyChecked(!isPrivacyChecked);
  };

  useEffect(() => {
    if (sAnsw === result && result !== 0) {
      setIsSQuestPassed(true);
    } else {
      setIsSQuestPassed(false);
    }
  }, [sAnsw, result]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isPrivacyChecked) {
        if (leftTries > 0) {
          if (isSQuestPassed) {
            const response = await axios.post("https://fischermachtpolitik.de/sendMail.php", formData);
            setSent(true);
            setResponseMessage(response.data.message);
            setFormData({ name: "", email: "", subject: "", message: "" });
            generateRandomNumber();
          } else {
            setSent(false);
            setResponseMessage("Die Sicherheitsabfrage wurde falsch beantwortet!");
            generateRandomNumber();
            setLeftTries(leftTries - 1);
          }
          setSAnsw("");
        } else {
          setSent(false);
          setResponseMessage("Die Sicherheitsabfrage wurde zu oft falsch beantwortet!");
        }
      } else {
        setSent(false);
        setResponseMessage("Nicht versendet, da der Datenschutzerklärung nicht eingewilligt wurde!");
      }
    } catch (error) {
      setSent(false);
      setResponseMessage("Die Mail konnte nicht versendet werden. Senden Sie eine E-Mail an rene@fischer-beilro.de");
    }
  };

  useEffect(() => {
    generateRandomNumber();
  }, []);

  return (
    <div className="fCCC contactContainer">
      <h1 className="defaultText red contact"> Sie haben Fragen? </h1>
      <h5 className="defaultText black contact2">
        Schreiben Sie mir, oder rufen Sie mich an: <a href="tel:+491757415855">+49 175 7415855</a>!
      </h5>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField name="name" label="Name" fullWidth value={formData.name} onChange={handleChange} required sx={textFieldStyle} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              required
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField name="subject" label="Überschrift" fullWidth value={formData.subject} onChange={handleChange} required sx={textFieldStyle} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="message"
              label="Nachricht"
              multiline
              minRows={4}
              maxRows={25}
              inputProps={{ maxLength }}
              helperText={`${formData.message.length}/${maxLength} Zeichen`}
              fullWidth
              value={formData.message}
              onChange={handleChangeArea}
              required
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField name="security" label={sQuest} fullWidth type="number" value={sAnsw} onChange={handleSecurity} required sx={textFieldStyle} />
          </Grid>
          <Grid item xs={12} className="fSCR">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPrivacyChecked}
                  onChange={handlePrivacyCheck}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleOutlineIcon style={{ color: "yellowgreen" }} />}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
            <div>
              <text className="defaultText black">
                Ich nehme zur Kenntnis, dass meine Angaben zur Beantwortung meiner Anfrage genutzt werden. (Weitere Informationen finden Sie in
                unseren{" "}
                <a href="https://fischermachtpolitik.de/privacy-policy" target="_blank" rel="noreferrer">
                  Datenschutzhinweisen
                </a>
                .)
              </text>
            </div>
          </Grid>
          <Grid item xs={12} className="fSSR sendGrid defaultText red sendResponse">
            <div className="sendGridButtonContainer">
              <Button
                type="submit"
                variant="contained"
                endIcon={<SendIcon />}
                sx={{
                  backgroundColor: "#E3000F",
                  "&:hover": {
                    backgroundColor: "#B4000C",
                  },
                }}
              >
                Senden
              </Button>
            </div>
            {responseMessage && !sent && <div className="sendGridResponseContainer">{responseMessage}</div>}
            {responseMessage && sent && <div className="sendGridResponseContainer defaultText green">{responseMessage}</div>}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

const textFieldStyle = {
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "#E3000F",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000000",
    },
    "&:hover fieldset": {
      borderColor: "#E3000F",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E3000F",
    },
  },
};
