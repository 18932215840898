import React, { useState, useEffect } from "react";
import "../../styles.css";
import { Button, IconButton, Drawer, List, ListItemText, ListItemButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TopBarLogo from "../../content/fischer_einer_fuer_alle_rot.png";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

function TopBar() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isVisible = scrollTop < 100; // Adjust as needed
      setIsVisible(isVisible);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToHeading = (id) => {
    navigate("/");
    setTimeout(() => {
      const heading = document.getElementById(id);
      const topbarHeight = document.querySelector(".topbar").offsetHeight;
      if (id === "start") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        if (heading) {
          const headingOffset = heading.getBoundingClientRect().top + window.scrollY + 3;
          window.scrollTo({
            top: headingOffset - topbarHeight,
            behavior: "smooth",
          });
        }
      }
    }, 100);
  };

  return (
    <div style={{ screenWidth }} className={`fCCC topbar ${isVisible ? "visible" : "hidden"}`}>
      <div className="fCCR topbarContainer">
        <div className="topbarLogoContainer">
          <img className="fCCC topbarLogo" src={TopBarLogo} alt="My Ims" onClick={() => scrollToHeading("start")} />
        </div>
        <div className="fECR topbarItemContainer">
          {isSmallScreen && (
            <IconButton onClick={() => setIsMenuOpen(true)}>
              <MenuIcon sx={{ color: "#E3000F" }} />
            </IconButton>
          )}
          {!isSmallScreen && (
            <>
              <Button sx={{ height: 20 }} onClick={() => scrollToHeading("start")}>
                <h1 className="menuItem">START</h1>
              </Button>
              <h1 className="menuItem"> | </h1>
              <Button sx={{ height: 20 }} onClick={() => scrollToHeading("standings")}>
                <h1 className="menuItem">DAFÜR STEHE ICH</h1>
              </Button>
              <h1 className="menuItem"> | </h1>
              <Button sx={{ height: 20 }} onClick={() => scrollToHeading("vocation")}>
                <h1 className="menuItem">BERUFUNG</h1>
              </Button>
              <h1 className="menuItem"> | </h1>
              <Button sx={{ height: 20 }} onClick={() => navigate("about-me")}>
                <h1 className="menuItem">ÜBER MICH</h1>
              </Button>
              <h1 className="menuItem"> | </h1>
              <Button sx={{ height: 20 }} onClick={() => scrollToHeading("contact")}>
                <h1 className="menuItem">KONTAKT</h1>
              </Button>
            </>
          )}
          <Drawer anchor="right" open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
            <List className="menuList">
              <ListItemButton
                onClick={() => {
                  scrollToHeading("start");
                  setIsMenuOpen(false);
                }}
              >
                <ListItemText primary="START" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  scrollToHeading("standings");
                  setIsMenuOpen(false);
                }}
              >
                <ListItemText primary="DAFÜR STEHE ICH" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  scrollToHeading("vocation");
                  setIsMenuOpen(false);
                }}
              >
                <ListItemText primary="BERUFUNG" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("about-me");
                  setIsMenuOpen(false);
                }}
              >
                <ListItemText primary="ÜBER MICH" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  scrollToHeading("contact");
                  setIsMenuOpen(false);
                }}
              >
                <ListItemText primary="KONTAKT" />
              </ListItemButton>
            </List>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
