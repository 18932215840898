import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "../../../styles.css";

export default function Wahlspruch({ icon, statement, delay }) {
  const imgRef = useRef(null);
  useEffect(() => {
    imgRef.current.style.animation = "moveInWahlspruch 1s ease-in-out backwards";
    imgRef.current.style.animationDelay = `${delay}s`;
    imgRef.current.style.opacity = 1;
  }, [delay]);

  return (
    <Grid ref={imgRef} container spacing={0} columnGap={1}>
      <Grid xs={0} sm={0} md={0} lg={0} xl={0} className="fCCC">
        {icon}
      </Grid>
      <Grid xs={10.5} sm={10} md={10} lg={10} xl={10} className="spruch">
        {statement.message}
      </Grid>
      <Grid lg={0} xl={0} className="fCCC" sx={{ opacity: 0 }}>
        {icon}
      </Grid>
      <Grid lg={9} xl={10} className="statement">
        {statement.heading}
      </Grid>
    </Grid>
  );
}
