import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TopBar from "../components/topbar/TopBar";
import ContentPage from "../components/ContentPage";
import "../styles.css";
import { CssBaseline } from "@mui/material";
import Impressum from "../components/Impressum";
import BottomBar from "../components/bottombar/BottomBar";
import PrivacyPolicy from "../components/PrivacyPolicy";
import AboutMe from "../components/aboutMe/AboutMe";

function App() {
  return (
    <CssBaseline>
      <Router basename="/">
        <div className="routerContainer">
          <header>
            <TopBar />
          </header>
          <main>
            <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
            <Routes>
              <Route path="/" element={<ContentPage />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/about-me" element={<AboutMe />} />
            </Routes>
          </main>
          <footer>
            <BottomBar></BottomBar>
          </footer>
        </div>
      </Router>
    </CssBaseline>
  );
}

export default App;
