import { useEffect, useRef, useState } from "react";
import "../../styles.css";
import { useMediaQuery } from "@mui/material";
import spd_rot from "../../content/SPD_Logo_Rot.svg";
import stimmzettel from "../../content/stimmzettel.png";

export default function Voting() {
  const imgRef = useRef(null);
  const textRef = useRef(null);
  const [scrollDirection] = useState("down");
  const isSmallScreen = useMediaQuery("(max-width:1200px)");

  useEffect(() => {
    textRef.current.style.animation = "moveOutToRight 1s ease-in-out forwards";
    textRef.current.style.opacity = 0;
    imgRef.current.style.animation = "moveOutToRight 1s ease-in-out forwards";
    imgRef.current.style.opacity = 0;
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (scrollDirection === "down") {
              textRef.current.style.animation = "moveIn 1.1s ease-in-out forwards";
              textRef.current.style.opacity = 1;
              imgRef.current.style.animation = "moveInFromRight 1s ease-in-out forwards";
              imgRef.current.style.opacity = 1;
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, [scrollDirection]);

  return (
    <>
      {!isSmallScreen && (
        <>
          <div className="fCCR votingContainer">
            <div className="fCCC votingContentContainer">
              <img src={spd_rot} className="votingImage" alt="not available"></img>
              <text ref={textRef} className="defaultText black votingText">
                Zwei Stimmen, doppelt stark: <br /> Erststimme für <strong>René Fischer</strong>, Zweitstimme <strong>SPD</strong>!
              </text>
            </div>
            <img ref={imgRef} src={stimmzettel} className="stimmzettel" alt="Stimmzettel"></img>
          </div>
        </>
      )}
      {isSmallScreen && (
        <>
          <div className="fSCC votingContainer">
            <div className="fSCC votingContentContainer">
              <img src={spd_rot} className="votingImage" alt="not available"></img>
              <text ref={textRef} className="defaultText black votingText">
                Zwei Stimmen, doppelt stark: <br /> Erststimme für <strong>René Fischer</strong>, Zweitstimme <strong>SPD</strong>!
              </text>
            </div>
            <img ref={imgRef} src={stimmzettel} className="stimmzettel" alt="Stimmzettel"></img>
          </div>
        </>
      )}
    </>
  );
}
